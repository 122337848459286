import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Deparmtents = () => {
  const { t, i18n } = useTranslation();
  const [showAllCommittees, setShowAllCommittees] = useState(false);
  const [showAllDepartments, setShowAllDepartments] = useState(false);

  const isRTL = i18n.language === "ar";

  const toggleShowAllCommittees = () => {
    setShowAllCommittees(!showAllCommittees);
  };

  const toggleShowAllDepartments = () => {
    setShowAllDepartments(!showAllDepartments);
  };

  return (
    <div className="relative bg-white text-black p-8 flex flex-col items-center">
      <div className="max-w-7xl w-full" dir={isRTL ? "rtl" : ""}>
        <h1 className="text-4xl font-bold text-red-600 mb-6 text-center">
          {t("JAD Committees and Departments")}
        </h1>
        <h2
          className="text-3xl font-semibold text-red-600 mb-4 mt-6"
          dir={isRTL ? "rtl" : ""}
        >
          {t("Committees of JAD")}
        </h2>
        <ul
          className={`list-disc ${
            isRTL ? "pl-0 pr-6" : "pl-6 pr-0"
          } mb-4 columns-1 md:columns-2`}
          dir={isRTL ? "rtl" : ""}
        >
          <li>1- {t("Committee of Jad Friends")}</li>
          <li>2- {t("JAD Representatives Around the World")}</li>
          <li>3- {t("Public Relations Communication Committee")}</li>
          <li>4- {t("International Relations Committee")}</li>
          <li>5- {t("Medical Committee")}</li>
          <li>6- {t("Media Committee")}</li>
          {showAllCommittees && (
            <>
              <li>7- {t("Financial Committee")}</li>
              <li>8- {t("Legal Committee")}</li>
              <li>9- {t("Secretary")}</li>
              <li>10- {t("Scientific Committee")}</li>
              <li>11- {t("Monitoring and Surveillance Committee")}</li>
              <li>12- {t("Activities Committee")}</li>
              <li>13- {t("Communication Committee")}</li>
            </>
          )}
        </ul>
        <button
          onClick={toggleShowAllCommittees}
          className="bg-blue-500 text-white py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:bg-blue-700 hover:scale-105 mb-6"
          dir={isRTL ? "rtl" : ""}
        >
          {showAllCommittees ? t("Show Less") : t("Show More")}
        </button>

        <h2
          className="text-3xl font-semibold text-red-600 mb-4 mt-6"
          dir={isRTL ? "rtl" : ""}
        >
          {t("Major departments of J.A.D")}
        </h2>
        <ul
          className={`list-disc ${
            isRTL ? "pl-0 pr-6" : "pl-6 pr-0"
          } mb-4 columns-1 md:columns-2`}
          dir={isRTL ? "rtl" : ""}
        >
          <li>{t("Sports Against Drugs")}</li>
          <li>{t("Parents Against Drugs")}</li>
          <li>{t("Training Center")}</li>
          <li>{t("Lebanese Bank Information on Addiction")}</li>
          <li>{t("Lebanese Experimental Laboratory on Drugs")}</li>
          {showAllDepartments && (
            <>
              <li>{t("Lebanese Monitoring Center for Drugs")}</li>
              <li>{t("Cannabis Section")}</li>
              <li>{t("Awareness Museum: Addiction Risk on Brain")}</li>
              <li>{t("Social Integration Office")}</li>
              <li>{t("Children Club")}</li>
              <li>{t("Awareness Section for Adolescents")}</li>
              <li>{t("JAD Europe")}</li>
              <li>{t("Youth Against HIV")}</li>
              <li>{t("Youth Charitable Assembly")}</li>
              <li>{t("Fleur de Vie")}</li>
              <li>{t("Youth to Limit Smoking")}</li>
              <li>{t("Scientific Items")}</li>
              <li>
                {t("The Arab Federation of N.G.O. for Drug Abuse Prevention")}
              </li>
              <li>{t("Awareness Program Related to the Risk of Mines")}</li>
              <li>{t("Internet Addiction Department")}</li>
              <li>{t("Committee of Jad Friends")}</li>
              <li>{t("Youth Against Alcohol")}</li>
              <li>{t("Reception and Follow-up Center")}</li>
              <li>{t("Mobile Exhibition & Library")}</li>
              <li>{t("General Administration")}</li>
              <li>{t("Medical Committee")}</li>
              <li>{t("JAD Representatives Around the World")}</li>
              <li>{t("Board members")}</li>
              <li>{t("Special Committees")}</li>
              <li>{t("Schools and Universities Awareness Program")}</li>
              <li>{t("Psychological Rehabilitation Program")}</li>
              <li>
                {t(
                  "Addiction Sections: Coffee Addictions, Tea Addictions, Soft Drinks Addictions, Food Addictions, etc."
                )}
              </li>
              <li>{t("Gambling Addiction Program")}</li>
              <li>{t("Detailed list of association's committees")}</li>
              <li>{t("General Administrative and Public Entities")}</li>
              <li>{t("Pharmacy of Love")}</li>
              <li>{t("Youth Against Drugs")}</li>
            </>
          )}
        </ul>
        <button
          onClick={toggleShowAllDepartments}
          className="bg-blue-500 text-white py-2 px-4 rounded-full transition duration-300 ease-in-out transform hover:bg-blue-700 hover:scale-105"
          dir={isRTL ? "rtl" : ""}
        >
          {showAllDepartments ? t("Show Less") : t("Show More")}
        </button>
      </div>
    </div>
  );
};

export default Deparmtents;
