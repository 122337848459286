import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Header: React.FC = () => {
  const { i18n } = useTranslation();
  const [showSidebar, setShowSidebar] = useState(false);
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    setLanguage(i18n.language);
  }, [i18n.language]);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const closeSidebar = () => {
    setShowSidebar(false);
  };

  const toggleLanguage = () => {
    const newLanguage = i18n.language === "en" ? "ar" : "en";
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
  };

  return (
    <>
      <header className="bg-red-600 text-white p-4 flex justify-between items-center">
        <div className="flex items-center">
          <img
            src="logo_jad.png"
            alt="JAD Youth Against Drugs Logo"
            className="h-14 w-14 mr-4 bg-white rounded-2xl"
          />
          <h1 className="text-2xl font-bold">JAD Youth Against Drugs</h1>
        </div>
        <div className="flex items-center">
          <nav className="hidden md:flex space-x-4">
            <Link
              className="mx-2 text-lg hover:underline"
              to="/"
              onClick={closeSidebar}
            >
              {i18n.t("Home")}
            </Link>
            <Link
              className="mx-2 text-lg hover:underline"
              to="/about"
              onClick={closeSidebar}
            >
              {i18n.t("About")}
            </Link>
            <Link
              className="mx-2 text-lg hover:underline"
              to="/culturalCenter"
              onClick={closeSidebar}
            >
              {i18n.t("Cultural Center")}
            </Link>
            <Link
              className="mx-2 text-lg hover:underline"
              to="/jaddepartments"
              onClick={closeSidebar}
            >
              {i18n.t("Departments")}
            </Link>
          </nav>
          <button
            onClick={toggleLanguage}
            className="bg-black text-white p-2 rounded ml-4"
          >
            {language === "en" ? "AR" : "EN"}
          </button>
          <button
            onClick={toggleSidebar}
            className="block md:hidden bg-black text-white p-2 rounded ml-4 z-10"
          >
            {showSidebar ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            )}
          </button>
        </div>
      </header>
      {showSidebar && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={closeSidebar}
        />
      )}
      <div
        className={`fixed top-0 right-0 h-full w-64 bg-gray-900 text-white py-4 transition-transform duration-300 z-50 ${
          showSidebar ? "transform translate-x-0" : "transform translate-x-full"
        }`}
      >
        <nav className="flex flex-col space-y-2">
          <Link
            className="text-lg hover:underline pl-4"
            to="/"
            onClick={closeSidebar}
          >
            {i18n.t("Home")}
          </Link>
          <Link
            className="text-lg hover:underline pl-4"
            to="/about"
            onClick={closeSidebar}
          >
            {i18n.t("About")}
          </Link>
          <Link
            className="text-lg hover:underline pl-4"
            to="/culturalCenter"
            onClick={closeSidebar}
          >
            {i18n.t("Cultural Center")}
          </Link>
          <Link
            className="text-lg hover:underline pl-4"
            to="/jaddepartments"
            onClick={closeSidebar}
          >
            {i18n.t("Departments")}
          </Link>
        </nav>
      </div>
    </>
  );
};

export default Header;
