import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      Home: "Home",
      About: "About us",
      Programs: "Programs",
      Contact: "Contact",
      Departments: "Departments",
      "Cultural Center": "Cultural Center",
      "Our Goals": "Our Goals",
      "Welcome to JAD Youth Against Drugs":
        "Welcome to JAD Youth Against Drugs",
      "Empowering the Lebanese youth and protecting them from the dangers of addiction.":
        "Empowering the Lebanese youth and protecting them from the dangers of addiction.",
      "Learn More": "Learn More",
      "Our Activities": "Our Activities",
      "Awareness Campaigns": "Awareness Campaigns",
      "Conducting campaigns to raise awareness about the dangers of drug addiction.":
        "Conducting campaigns to raise awareness about the dangers of drug addiction.",
      "Training Programs": "Training Programs",
      "Provide all kinds of training for law enforcement, medical groups, and volunteers in drug-related issues.":
        "Provide all kinds of training for law enforcement, medical groups, and volunteers in drug-related issues.",
      "Treatment and Rehabilitation": "Treatment and Rehabilitation",
      "Offering medical care and psychological rehabilitation for drug addicts.":
        "Offering medical care and psychological rehabilitation for drug addicts.",
      "Success Stories": "Success Stories",
      "Read stories of individuals who have successfully overcome addiction with our help.":
        "Read stories of individuals who have successfully overcome addiction with our help.",
      "JAD Briefly": "JAD Briefly",
      "JAD is a Lebanese association established in 1981 AD, and is the first Arab association concerned with the field of prevention of the dangers of psychotropic substances.":
        "JAD is a Lebanese association established in 1981 AD, and is the first Arab association concerned with the field of prevention of the dangers of psychotropic substances.",
      "JAD includes members from various Lebanese sects.":
        "JAD includes members from various Lebanese sects.",
      "There are seven basic licensed associations working within JAD that are concerned with issues related to addiction.":
        "There are seven basic licensed associations working within JAD that are concerned with issues related to addiction.",
      "JAD's main departments consist of thirty-four sections specializing in awareness, training, and treatment.":
        "JAD's main departments consist of thirty-four sections specializing in awareness, training, and treatment.",
      "JAD owns a specialized cultural museum for awareness and training, unique globally with forty thousand pieces collected from 92 countries over 42 years.":
        "JAD owns a specialized cultural museum for awareness and training, unique globally with forty thousand pieces collected from 92 countries over 42 years.",
      "JAD's main work specialization in percentage:":
        "JAD's main work specialization in percentage:",
      "40% awareness": "40% awareness",
      "20% training": "20% training",
      "20% treatment": "20% treatment",
      "10% rehabilitation": "10% rehabilitation",
      "5% follow-up": "5% follow-up",
      "5% essential topics": "5% essential topics",
      "JAD collaborates with several security and preventive bodies, whether local or international, serving as a scientific reference.":
        "JAD collaborates with several security and preventive bodies, whether local or international, serving as a scientific reference.",
      "JAD is connected to numerous governmental and non-governmental institutions worldwide through over four hundred partnerships and cooperation agreements.":
        "JAD is connected to numerous governmental and non-governmental institutions worldwide through over four hundred partnerships and cooperation agreements.",
      "It has representatives and centers in over ninety-two countries.":
        "It has representatives and centers in over ninety-two countries.",
      "Most of JAD's work is confidential and free of charge.":
        "Most of JAD's work is confidential and free of charge.",
      Email: "Email",
      Telefax: "Telefax",
      Mobile: "Mobile",
      "Conferences and Seminars": "Conferences and Seminars",
      "To educate all groups from different ages to fight against drugs and social problems":
        "To educate all groups from different ages to fight against drugs and social problems",
      "Develop Research": "Develop Research",
      "Develop research and study centers for addiction, alcohol abuse, smoking, and AIDS.":
        "Develop research and study centers for addiction, alcohol abuse, smoking, and AIDS.",
      Trainings: "Trainings",
      "Providing training for law enforcement, medical groups, and volunteers on drug-related issues.":
        "Providing training for law enforcement, medical groups, and volunteers on drug-related issues.",

      "Youth Against Drugs": "Youth Against Drugs",
      "Founded in 1981": "Founded in 1981",
      "Registered with the Ministry of Interior under No. 164/AD, member of the National Council for Combating Drugs in the Cabinet under No. 10, meeting No. 23 dated 1992/4/22.":
        "Registered with the Ministry of Interior under No. 164/AD, member of the National Council for Combating Drugs in the Cabinet under No. 10, meeting No. 23 dated 1992/4/22.",
      "Works to protect youth from the dangers of drugs, smoking, alcohol addiction, and AIDS.":
        "Works to protect youth from the dangers of drugs, smoking, alcohol addiction, and AIDS.",
      "Youth Charitable Assembly": "Youth Charitable Assembly",
      "Founded in 2000": "Founded in 2000",
      "Licensed by the Ministry of Interior and Municipalities under No. 82/AD.":
        "Licensed by the Ministry of Interior and Municipalities under No. 82/AD.",
      "An association specialized in helping the children and families of addicted individuals, homeless individuals, and orphans.":
        "An association specialized in helping the children and families of addicted individuals, homeless individuals, and orphans.",
      "JAD Europe": "JAD Europe",
      "Founded in 2001": "Founded in 2001",
      "The first licensed Arab association operating in the European Union, licensed in Germany, Staufen Court / European Union, under No. 519.":
        "The first licensed Arab association operating in the European Union, licensed in Germany, Staufen Court / European Union, under No. 519.",
      "Its main activities include protecting the Lebanese community in Europe from the dangers of drugs, providing therapeutic, training, and sports programs against drugs.":
        "Its main activities include protecting the Lebanese community in Europe from the dangers of drugs, providing therapeutic, training, and sports programs against drugs.",
      "Sports Against Drugs": "Sports Against Drugs",
      "Founded in 2002": "Founded in 2002",
      "Licensed by the Ministry of Youth and Sports under No. 1/66 - 2014.":
        "Licensed by the Ministry of Youth and Sports under No. 1/66 - 2014.",
      "Includes nine different sports teams and is a member of the Lebanese Basketball Federation. Its goal is to integrate addicts and protect youth from the dangers of drugs through sports.":
        "Includes nine different sports teams and is a member of the Lebanese Basketball Federation. Its goal is to integrate addicts and protect youth from the dangers of drugs through sports.",
      "The Arab Federation of N.G.O. for Drug Abuse Prevention":
        "The Arab Federation of N.G.O. for Drug Abuse Prevention",
      "Headquarters: Egypt, Cairo, since 2006":
        "Headquarters: Egypt, Cairo, since 2006",
      "JAD represents Lebanon in the federation.":
        "JAD represents Lebanon in the federation.",
      "Parents Against Drugs": "Parents Against Drugs",
      "Registered with the Ministry of Interior and Municipalities under No. 164/AD.":
        "Registered with the Ministry of Interior and Municipalities under No. 164/AD.",
      "A committee specialized in solving parental issues, which are the primary cause of drug addiction. It includes a specialized team that offers training and awareness programs for parents.":
        "A committee specialized in solving parental issues, which are the primary cause of drug addiction. It includes a specialized team that offers training and awareness programs for parents.",
      "Awareness Program Related to the Risk of Mines":
        "Awareness Program Related to the Risk of Mines",
      "JAD is a founding member of the office working in the field of awareness and guidance about the dangers of mines since 2006.":
        "JAD is a founding member of the office working in the field of awareness and guidance about the dangers of mines since 2006.",
      "JAD Family": "JAD Family",
      "JAD Committees and Departments": "JAD Committees and Departments",
      "Committees of JAD": "Committees of JAD",
      "Major departments of J.A.D": "Major departments of J.A.D",
      "Committee of Jad Friends": "Committee of Jad Friends",
      "JAD Representatives Around the World":
        "JAD Representatives Around the World",
      "Public Relations Communication Committee":
        "Public Relations Communication Committee",
      "International Relations Committee": "International Relations Committee",
      "Medical Committee": "Medical Committee",
      "Media Committee": "Media Committee",
      "Financial Committee": "Financial Committee",
      "Legal Committee": "Legal Committee",
      Secretary: "Secretary",
      "Scientific Committee": "Scientific Committee",
      "Monitoring and Surveillance Committee":
        "Monitoring and Surveillance Committee",
      "Activities Committee": "Activities Committee",
      "Communication Committee": "Communication Committee",
      "Training Center": "Training Center",
      "Lebanese Bank Information on Addiction":
        "Lebanese Bank Information on Addiction",
      "Lebanese Experimental Laboratory on Drugs":
        "Lebanese Experimental Laboratory on Drugs",
      "Lebanese Monitoring Center for Drugs":
        "Lebanese Monitoring Center for Drugs",
      "Cannabis Section": "Cannabis Section",
      "Awareness Musuem: Addiction Risk on Brain":
        "Awareness Musuem: Addiction Risk on Brain",
      "Social Integration Office": "Social Integration Office",
      "Children Club": "Children Club",
      "Awareness Section for Adolescents": "Awareness Section for Adolescents",
      "Youth Against HIV": "Youth Against HIV",
      "Fleur de Vie": "Fleur de Vie",
      "Youth to Limit Smoking": "Youth to Limit Smoking",
      "Scientific Items": "Scientific Items",

      "Internet Addiction Department": "Internet Addiction Department",
      "Youth Against Alcohol": "Youth Against Alcohol",
      "Reception and Follow-up Center": "Reception and Follow-up Center",
      "Mobile Exhibition & Library": "Mobile Exhibition & Library",
      "General Administration": "General Administration",
      "Board members": "Board members",
      "Special Committees": "Special Committees",
      "Schools and Universities Awareness Program":
        "Schools and Universities Awareness Program",
      "Psychological Rehabilitation Program":
        "Psychological Rehabilitation Program",
      "Addiction Sections: Coffee Addictions, Tea Addictions, Soft Drinks Addictions, Food Addictions, etc.":
        "Addiction Sections: Coffee Addictions, Tea Addictions, Soft Drinks Addictions, Food Addictions, etc.",
      "Gambling Addiction Program": "Gambling Addiction Program",
      "Detailed list of association's committees":
        "Detailed list of association's committees",
      "General Administrative and Public Entities":
        "General Administrative and Public Entities",
      "Pharmacy of Love": "Pharmacy of Love",
      "Show More": "Show More",
      "Show Less": "Show Less",
      "Museum, Gallery, Library": "Museum, Gallery, Library",
      "To raise awareness and to train its visitors on the danger of all psychotropic substances (addiction)":
        "To raise awareness and to train its visitors on the danger of all psychotropic substances (addiction)",
      "This center took about 33 Years to be achieved":
        "This center took about 33 Years to be achieved",
      'The main subjects we treat in the center are: "Drug - Tobacco - Alcohol"':
        'The main subjects we treat in the center are: "Drug - Tobacco - Alcohol"',
      "In Cooperation with": "In Cooperation with",
    },
  },
  ar: {
    translation: {
      Home: "الرئيسية",
      About: "من نحن",
      Programs: "برامجنا",
      Contact: "اتصل بنا",
      Departments: "الأقسام",

      "Our Goals": "أهدافنا",

      "Welcome to JAD Youth Against Drugs":
        "مرحبًا بكم في جمعية جاد شباب ضد المخدرات",
      "Empowering the Lebanese youth and protecting them from the dangers of addiction.":
        "تمكين الشباب اللبناني وحمايتهم من مخاطر الإدمان",
      "Learn More": "اعرف أكثر",
      "Our Activities": "أنشطتنا",
      "Awareness Campaigns": "حملات التوعية",
      "Conducting campaigns to raise awareness about the dangers of drug addiction.":
        "إجراء حملات لرفع الوعي بمخاطر إدمان المخدرات",
      "Training Programs": "برامج التدريب",
      "Providing training for law enforcement, medical groups, and volunteers.":
        "تقديم التدريب لقوات إنفاذ القانون والمجموعات الطبية والمتطوعين.",
      "Treatment and Rehabilitation": "العلاج وإعادة التأهيل",
      "Offering medical care and psychological rehabilitation for drug addicts.":
        "تقديم الرعاية الطبية وإعادة التأهيل النفسي للمدمنين",
      "Success Stories": "قصص النجاح",
      "Read stories of individuals who have successfully overcome addiction with our help.":
        "اقرأ قصص الأفراد الذين تمكنوا من التغلب على الإدمان بمساعدتنا.",
      "JAD Briefly": "لمحة عن جاد",
      "JAD is a Lebanese association established in 1981 AD, and is the first Arab association concerned with the field of prevention of the dangers of psychotropic substances.":
        "جاد هي جمعية لبنانية تأسست عام 1981 م، وهي أول جمعية عربية تهتم بمجال الوقاية من مخاطر المواد النفسية.",
      "JAD includes members from various Lebanese sects.":
        "تضم جاد أعضاء من مختلف الطوائف اللبنانية.",
      "There are seven basic licensed associations working within JAD that are concerned with issues related to addiction.":
        "توجد سبع جمعيات مرخصة أساسية تعمل داخل جاد تهتم بالقضايا المتعلقة بالإدمان.",
      "JAD's main departments consist of thirty-four sections specializing in awareness, training, and treatment.":
        "تتكون الأقسام الرئيسية لجاد من أربعة وثلاثين قسمًا متخصصًا في التوعية والتدريب والعلاج.",
      "JAD owns a specialized cultural museum for awareness and training, unique globally with forty thousand pieces collected from 92 countries over 42 years.":
        "تمتلك جاد متحفًا ثقافيًا متخصصًا للتوعية والتدريب، وهو فريد من نوعه عالميًا مع أربعين ألف قطعة جمعت من 92 دولة على مدار 42 عامًا.",
      "JAD's main work specialization in percentage:":
        ":تخصص العمل الرئيسي لجاد بالنسبة المئوية",
      "40% awareness": "40% توعية",
      "20% training": "20% تدريب",
      "20% treatment": "20% علاج",
      "10% rehabilitation": "10% إعادة تأهيل",
      "5% follow-up": "5% متابعة",
      "5% essential topics": "5% مواضيع أساسية",
      "JAD collaborates with several security and preventive bodies, whether local or international, serving as a scientific reference.":
        "تتعاون جاد مع عدة هيئات أمنية ووقائية، سواء كانت محلية أو دولية، وتعمل كمرجع علمي.",
      "JAD is connected to numerous governmental and non-governmental institutions worldwide through over four hundred partnerships and cooperation agreements.":
        "ترتبط جاد بالعديد من المؤسسات الحكومية وغير الحكومية في جميع أنحاء العالم من خلال أكثر من أربعمائة شراكة واتفاقية تعاون.",
      "It has representatives and centers in over ninety-two countries.":
        "لديها ممثلون ومراكز في أكثر من اثنتين وتسعين دولة.",
      "Most of JAD's work is confidential and free of charge.":
        "معظم عمل جاد سري ومجاني",
      Email: "البريد الإلكتروني",
      Telefax: "الفاكس",
      Mobile: "الجوال",
      "Conferences and Seminars": "المؤتمرات والندوات",
      "To educate all groups from different ages to fight against drugs and social problems":
        "لتثقيف جميع الفئات من مختلف الأعمار لمحاربة المخدرات والمشاكل الاجتماعي",
      "Develop Research": "تطوير الأبحاث",
      "Develop research and study centers for addiction, alcohol abuse, smoking, and AIDS.":
        "تطوير مراكز البحث والدراسة للإدمان وإساءة استخدام الكحول والتدخين والإيدز",
      Trainings: "التدريبات",
      "Provide all kinds of training for law enforcement, medical groups, and volunteers in drug-related issues.":
        "تقديم التدريب لقوات إنفاذ القانون والمجموعات الطبية والمتطوعين في قضايا تتعلق بالمخدرات",
      "Support the fight against drugs and social problems and to continue our mission in the service of the Lebanese youth in order to keep them away from addiction.":
        "دعم الحرب ضد المخدرات والمشاكل الاجتماعية ومواصلة مهمتنا في خدمة الشباب اللبناني للحفاظ عليهم بعيدين عن الادمان",
      "Protect every drug addict, offer the needed guidance and useful instructions and provide him with medical care and psychological rehabilitation.":
        "حماية كل مدمن على المخدرات، وتقديم التوجيه والتعليمات اللازمة وتوفير الرعاية الطبية وإعادة التأهيل النفسي له",
      "Cooperate with governmental and NGOs, institutes and schools by offering expertise and advice and by conducting lectures to keep the young generation from the drug's addiction.":
        "التعاون مع الجهات الحكومية والمنظمات غير الحكومية والمعاهد والمدارس من خلال تقديم الخبرة والنصح وإلقاء المحاضرات للحفاظ على الجيل الشاب بعيدًا عن إدمان المخدرات",
      "Decrease the crime rate resulting from drug addiction.":
        "تقليل معدل الجريمة نتيجة لإدمان المخدرات",
      "Youth Against Drugs": "شباب ضد المخدرات",
      "Founded in 1981": "تأسست في عام 1981",
      "Registered with the Ministry of Interior under No. 164/AD, member of the National Council for Combating Drugs in the Cabinet under No. 10, meeting No. 23 dated 1992/4/22.":
        "مسجلة لدى وزارة الداخلية تحت الرقم 164/AD، عضو في المجلس الوطني لمكافحة المخدرات في مجلس الوزراء تحت الرقم 10، الاجتماع رقم 23 بتاريخ 1992/4/22",
      "Works to protect youth from the dangers of drugs, smoking, alcohol addiction, and AIDS.":
        "تعمل على حماية الشباب من مخاطر المخدرات، التدخين، إدمان الكحول، والإيدز.",
      "Youth Charitable Assembly": "الجمعية الخيرية للشباب",
      "Founded in 2000": "تأسست في عام 2000",
      "Licensed by the Ministry of Interior and Municipalities under No. 82/AD.":
        "مرخصة من وزارة الداخلية والبلديات تحت الرقم 82/AD.",
      "An association specialized in helping the children and families of addicted individuals, homeless individuals, and orphans.":
        "جمعية متخصصة في مساعدة أطفال وأسر الأفراد المدمنين، والأشخاص المشردين، والأيتام.",
      "JAD Europe": "جاد أوروبا",
      "Founded in 2001": "تأسست في عام 2001",
      "The first licensed Arab association operating in the European Union, licensed in Germany, Staufen Court / European Union, under No. 519.":
        "أول جمعية عربية مرخصة تعمل في الاتحاد الأوروبي، مرخصة في ألمانيا، محكمة شتوفن / الاتحاد الأوروبي، تحت الرقم 519.",
      "Its main activities include protecting the Lebanese community in Europe from the dangers of drugs, providing therapeutic, training, and sports programs against drugs.":
        "تشمل أنشطتها الرئيسية حماية المجتمع اللبناني في أوروبا من مخاطر المخدرات، وتقديم البرامج العلاجية والتدريبية والرياضية ضد المخدرات.",
      "Sports Against Drugs": "الرياضة ضد المخدرات",
      "Founded in 2002": "تأسست في عام 2002",
      "Licensed by the Ministry of Youth and Sports under No. 1/66 - 2014.":
        "مرخصة من وزارة الشباب والرياضة تحت الرقم 1/66 - 2014.",
      "Includes nine different sports teams and is a member of the Lebanese Basketball Federation. Its goal is to integrate addicts and protect youth from the dangers of drugs through sports.":
        "تضم تسعة فرق رياضية مختلفة وهي عضو في الاتحاد اللبناني لكرة السلة. هدفها دمج المدمنين وحماية الشباب من مخاطر المخدرات من خلال الرياضة.",
      "The Arab Federation of N.G.O. for Drug Abuse Prevention":
        "الاتحاد العربي للمنظمات غير الحكومية للوقاية من تعاطي المخدرات",
      "Headquarters: Egypt, Cairo, since 2006":
        "المقر: مصر، القاهرة، منذ عام 2006",
      "JAD represents Lebanon in the federation.": "تمثل جاد لبنان في الاتحاد.",
      "Parents Against Drugs": "الآباء ضد المخدرات",
      "Registered with the Ministry of Interior and Municipalities under No. 164/AD.":
        "مسجلة لدى وزارة الداخلية والبلديات تحت الرقم .164/AD",
      "A committee specialized in solving parental issues, which are the primary cause of drug addiction. It includes a specialized team that offers training and awareness programs for parents.":
        "لجنة متخصصة في حل القضايا الأبوية، التي تعتبر السبب الرئيسي لإدمان المخدرات. تضم فريقًا متخصصًا يقدم برامج تدريبية وتوعوية للآباء.",
      "Awareness Program Related to the Risk of Mines":
        "برنامج التوعية المتعلقة بمخاطر الألغام",
      "JAD is a founding member of the office working in the field of awareness and guidance about the dangers of mines since 2006.":
        "جاد هي عضو مؤسس في المكتب العامل في مجال التوعية والإرشاد حول مخاطر الألغام منذ عام 2006.",
      "JAD Family": "عائلة جاد",
      "JAD Committees and Departments": "لجان وأقسام جاد",
      "Committees of JAD": "لجان جاد",
      "Major departments of J.A.D": "الأقسام الرئيسية في جمعية جاد",
      "Committee of Jad Friends": "لجنة أصدقاء جاد",
      "JAD Representatives Around the World": "لجنة مندوبي جاد حول العالم",
      "Public Relations Communication Committee":
        "لجنة التواصل للعلاقات العامة",
      "International Relations Committee": "لجنة العلاقات الدولية",
      "Medical Committee": "اللجنة الطبية",
      "Media Committee": "لجنة الإعلام",
      "Financial Committee": "اللجنة المالية",
      "Legal Committee": "اللجنة القانونية",
      Secretary: "أمانة السر",
      "Scientific Committee": "اللجنة العلمية",
      "Monitoring and Surveillance Committee": "لجنة المراقبة والترصد",
      "Activities Committee": "لجنة الأنشطة",
      "Communication Committee": "لجنة التواصل",
      "Training Center": "مركز التدريب",
      "Lebanese Bank Information on Addiction":
        "البنك اللبناني للمعلومات الخاصة بالإدمان",
      "Lebanese Experimental Laboratory on Drugs":
        "المختبر اللبناني للتجارب والتوعية من مخاطر المخدرات",
      "Lebanese Monitoring Center for Drugs":
        "المرصد اللبناني لمكافحة المخدرات",
      "Cannabis Section": "قسم القنب الهندي (الحشيش)",
      "Awareness Musuem: Addiction Risk on Brain":
        "المتحف الثقافي المتخصص للتوعية من أخطار المؤثرات العقلية",
      "Social Integration Office": "مكتب المتابعة ودمج المدمن بالمجتمع",
      "Children Club": "نادي الأطفال",
      "Awareness Section for Adolescents": "قسم التوعية للمراهقين",
      "Youth Against HIV": "شباب لمواجهة مرض الإيدز",
      "Fleur de Vie": "زهرة الحياة",
      "Youth to Limit Smoking": "شباب للحد من التدخين",
      "Scientific Items": "قسم الدعم بالمواد العلمية والتدريبية",

      "Internet Addiction Department":
        "قسم سوء استعمال الكمبيوتر وخطر إدمان الإنترنت",
      "Youth Against Alcohol": "شباب ضد الكحول",
      "Reception and Follow-up Center": "مركز الاستقبال والرعاية اللاحقة",
      "Mobile Exhibition & Library": "المعرض المتنقل والمكتبة المتنقلة",
      "General Administration": "الهيئة الإدارية العامة",
      "Board members": "مجلس الأمناء",
      "Special Committees": "اللجان المختصة",
      "Schools and Universities Awareness Program":
        "برنامج التوعية الخاص لطلاب المدارس والجامعات",
      "Psychological Rehabilitation Program": "برنامج التأهيل النفسي",
      "Addiction Sections: Coffee Addictions, Tea Addictions, Soft Drinks Addictions, Food Addictions, etc":
        "أقسام الإدمان: القهوة - الشاي - المشروبات الغازية - إدمان الأكل - الخ",
      "Gambling Addiction Program": "برنامج الحد من إدمان الميسر (القمار)",
      "Detailed list of association's committees": "لائحة مفصلة للجان الجمعية",
      "General Administrative and Public Entities": "الهيئات الإدارية والعامة",
      "Pharmacy of Love": "صيدلية المحبة",
      "Show More": "عرض المزيد",
      "Show Less": "عرض أقل",
      "Cultural Center": "المركز الثقافي",
      "Museum, Gallery, Library": "متحف، معرض، مكتبة",
      "To raise awareness and to train its visitors on the danger of all psychotropic substances (addiction)":
        "لزيادة الوعي وتدريب الزوار على مخاطر جميع المواد النفسية (الإدمان).",
      "This center took about 33 Years to be achieved":
        " استغرق تحقيق هذا المركز حوالي 33 عامًا.",
      'The main subjects we treat in the center are: "Drug - Tobacco - Alcohol"':
        'المواضيع الرئيسية التي نتناولها في المركز هي: "المخدرات - التبغ - الكحول".',
      "In Cooperation with": "بالتعاون مع",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    lng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
