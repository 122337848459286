import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const activities = [
  {
    titleKey: "Awareness Campaigns",
    descriptionKey:
      "Conducting campaigns to raise awareness about the dangers of drug addiction.",
    image: "/awareness_campaigns.jpg",
  },
  {
    titleKey: "Conferences and Seminars",
    descriptionKey:
      "To educate all groups from different ages to fight against drugs and social problems",
    image: "/conferences_seminars.jpg",
  },
  {
    titleKey: "Treatment and Rehabilitation",
    descriptionKey:
      "Offering medical care and psychological rehabilitation for drug addicts.",
    image: "/treatments.jpg",
  },
  {
    titleKey: "Develop Research",
    descriptionKey:
      "Develop research and study centers for addiction, alcohol abuse, smoking, and AIDS.",
    image: "/development_research.jpg",
  },
  {
    titleKey: "Trainings",
    descriptionKey:
      "Provide all kinds of training for law enforcement, medical groups, and volunteers in drug-related issues.",
    image: "/trainings.jpg",
  },
];

const Home: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center p-8 min-h-screen flex flex-col justify-center">
      <section
        className="hero max-h-96 flex items-center justify-center bg-cover bg-center text-white p-8 rounded-lg shadow-lg"
        style={{
          backgroundImage: "url('/hero_image.jpg')",
        }}
      >
        <div className="bg-black bg-opacity-50 p-4 md:p-8 rounded">
          <h2 className="text-xl md:text-4xl font-bold">
            {t("Welcome to JAD Youth Against Drugs")}
          </h2>
          <p className="mt-1 md:mt-4">
            {t(
              "Empowering the Lebanese youth and protecting them from the dangers of addiction."
            )}
          </p>
          <Link
            to="/about"
            className="bg-red-600 text-white p-2 rounded mt-4 inline-block"
          >
            {t("Learn More")}
          </Link>
        </div>
      </section>

      <section className="goals mt-8">
        <h3 className="text-3xl font-bold">{t("Our Goals")}</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 mt-8">
          <div className="goal-card relative bg-white text-black p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 flex justify-center items-center">
            <div className="triangle-bottom-right"></div>
            <p>
              {t(
                "Support the fight against drugs and social problems and to continue our mission in the service of the Lebanese youth in order to keep them away from addiction."
              )}
            </p>
          </div>
          <div className="goal-card relative bg-white text-black p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 flex justify-center items-center">
            <div className="triangle-bottom-right"></div>
            <p>
              {t(
                "Protect every drug addict, offer the needed guidance and useful instructions and provide him with medical care and psychological rehabilitation."
              )}
            </p>
          </div>
          <div className="goal-card relative bg-white text-black p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 flex justify-center items-center">
            <div className="triangle-bottom-right"></div>
            <p>
              {t(
                "Cooperate with governmental and NGOs, institutes and schools by offering expertise and advice and by conducting lectures to keep the young generation from the drug's addiction."
              )}
            </p>
          </div>
          <div className="goal-card relative bg-white text-black p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105 flex justify-center items-center">
            <div className="triangle-bottom-right"></div>
            <p>{t("Decrease the crime rate resulting from drug addiction.")}</p>
          </div>
        </div>
      </section>

      <section className="activities mt-8">
        <h3 className="text-3xl font-bold">{t("Our Activities")}</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
          {activities.map((activity, index) => (
            <div
              key={index}
              className="activity-card relative bg-white text-black p-6 rounded-lg shadow-lg transition-transform transform hover:scale-105"
            >
              <img
                src={activity.image}
                alt={t(activity.titleKey)}
                className="h-40 w-full object-cover rounded-t-lg mb-4"
              />
              <h4 className="text-2xl font-semibold">{t(activity.titleKey)}</h4>
              <p className="mt-2">{t(activity.descriptionKey)}</p>
            </div>
          ))}
        </div>
      </section>

      {/* <section className="testimonials mt-12">
        <h3 className="text-3xl font-bold">{t("Success Stories")}</h3>
        <p className="mt-4">
          {t(
            "Read stories of individuals who have successfully overcome addiction with our help."
          )}
        </p>
      </section> */}
    </div>
  );
};

export default Home;
