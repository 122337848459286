// src/components/Footer.tsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook } from "@fortawesome/free-brands-svg-icons";
import { useTranslation } from "react-i18next";

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-black text-white py-8">
      <div className="container mx-auto px-4">
        <div className="footer-content flex flex-col md:flex-row justify-between items-center">
          <div className="contact-info text-center md:text-left mb-4 md:mb-0">
            <p className="mb-2">
              {t("Email")}:{" "}
              <a href="mailto:Jaddrogue@gmail.com" className="text-red-600">
                Jaddrogue@gmail.com
              </a>
            </p>
            <p className="mb-2">
              {t("Telefax")}:{" "}
              <a href="tel:+9619942856" className="text-red-600">
                +961 9 942856
              </a>
            </p>
            <p className="mb-2">
              {t("Mobile")}:{" "}
              <a href="tel:+9613749484" className="text-red-600">
                +961 3 749484
              </a>
            </p>
          </div>
          <div className="social-media flex space-x-4">
            <a
              href="https://www.instagram.com/Jaddrogue"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-red-600"
            >
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a
              href="https://www.facebook.com/JAD1981/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white hover:text-red-600"
            >
              <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
