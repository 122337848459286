import React from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CulturalCenter: React.FC = () => {
  const { t, i18n } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768, 
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const images = [
    "cul1.jpg",
    "cul2.jpg",
    "cul3.png",
    "cul4.png",
  ];

  const cooperationLogos = [
    "Kiwa_amn_dakhele.png",
    "CCIAB.png",
    "douanes_libanais.png",
    "Middle-East-Airlines-Logo.png",
    "world_vision.png",
    
  ];

  const isRTL = i18n.language === "ar";

  return (
    <div className="bg-white text-black p-8 flex flex-col items-center">
      <div className="max-w-7xl w-full">
        <h1 className="text-4xl font-bold text-red-600 mb-6 text-center">
          {t("Cultural Center")}
        </h1>
        <div className="mb-8 w-full">
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} className="flex justify-center rounded-3xl">
                <img
                  src={image}
                  alt={`${t("Cultural Center")} ${index + 1}`}
                  className="w-full h-auto max-h-96 object-contain rounded-3xl"
                />
              </div>
            ))}
          </Slider>
        </div>
        <p className="text-xl mb-4" dir={isRTL ? "rtl" : ""}>
          {t("Museum, Gallery, Library")}
        </p>
        <p className="text-lg mb-4" dir={isRTL ? "rtl" : ""}>
          {t(
            "To raise awareness and to train its visitors on the danger of all psychotropic substances (addiction)"
          )}
        </p>
        <p className="text-lg mb-4 " dir={isRTL ? "rtl" : ""}>
          {t("This center took about 33 Years to be achieved")}
        </p>
        <p className="text-lg mb-6 " dir={isRTL ? "rtl" : ""}>
          {t(
            'The main subjects we treat in the center are: "Drug - Tobacco - Alcohol"'
          )}
        </p>
        <h2
          className="text-2xl font-semibold text-red-600 mb-4"
          dir={isRTL ? "rtl" : ""}
        >
          {t("In Cooperation with")}
        </h2>
        <div className="flex flex-wrap justify-around mb-6">
          {cooperationLogos.map((logo, index) => (
            <div key={index} className="p-2">
              <img
                src={logo}
                alt={`Cooperation Logo ${index + 1}`}
                className="h-28 object-contain"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CulturalCenter;
