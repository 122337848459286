import React from "react";
import { useTranslation } from "react-i18next";

const About: React.FC = () => {
  const { t, i18n } = useTranslation();

  const isRTL = i18n.language === "ar";

  return (
    <div className="relative bg-white text-black p-8 flex flex-col items-center">
      <div className="max-w-7xl w-full">
        <h1 className="text-4xl font-bold text-red-600 mb-6 text-center">
          {t("About")}
        </h1>
        <div className="w-full flex justify-center">
          <img
            src="hero_image-transformed.jpeg"
            alt="Hero"
            className="w-full h-auto mb-6 rounded-lg"
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          <div
            className={`col-span-1 ${isRTL ? "md:col-start-2" : ""}`}
            dir={isRTL ? "rtl" : ""}
          >
            <p className="text-lg mb-4">
              {t(
                "JAD is a Lebanese association established in 1981 AD, and is the first Arab association concerned with the field of prevention of the dangers of psychotropic substances."
              )}
              {t("JAD includes members from various Lebanese sects.")}
              {t(
                "There are seven basic licensed associations working within JAD that are concerned with issues related to addiction."
              )}
              {t(
                "JAD's main departments consist of thirty-four sections specializing in awareness, training, and treatment."
              )}
              {t(
                "JAD owns a specialized cultural museum for awareness and training, unique globally with forty thousand pieces collected from 92 countries over 42 years."
              )}
            </p>
          </div>
          <div
            className={`col-span-1 ${
              isRTL ? "md:row-start-1 " : "md:col-start-2"
            }`}
          >
            <p className={`text-lg mb-4 ${isRTL ? "text-right" : ""}`}>
              {t("JAD's main work specialization in percentage:")}
              <ul
                className={`list-disc pl-6 mb-4 ${isRTL ? "rtl" : ""}`}
                dir={isRTL ? "rtl" : ""}
              >
                <li>{t("40% awareness")}</li>
                <li>{t("20% training")}</li>
                <li>{t("20% treatment")}</li>
                <li>{t("10% rehabilitation")}</li>
                <li>{t("5% follow-up")}</li>
                <li>{t("5% essential topics")}</li>
              </ul>
              {t(
                "JAD collaborates with several security and preventive bodies, whether local or international, serving as a scientific reference."
              )}
              {t(
                "JAD is connected to numerous governmental and non-governmental institutions worldwide through over four hundred partnerships and cooperation agreements."
              )}
              {t(
                "It has representatives and centers in over ninety-two countries."
              )}
              {t("Most of JAD's work is confidential and free of charge.")}
            </p>
          </div>
        </div>
        <h2
          className="text-3xl font-semibold text-red-600 mb-4 mt-6"
          dir={isRTL ? "rtl" : ""}
        >
          {t("JAD Family")}
        </h2>
        <div
          className="grid grid-cols-1 md:grid-cols-2 gap-10 mb-6"
          dir={isRTL ? "rtl" : ""}
        >
          <div className="col-span-1  md:col-span-1 md:row-span-2 flex justify-center">
            <img
              src={isRTL ? "tree_arabic.jpg" : "tree_english.jpg"}
              alt="JAD Family"
              className="w-full h-auto rounded-3xl shadow-lg "
            />
          </div>
          <div className="col-span-1 flex flex-col min-h-full">
            <div
              className="bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col flex-grow"
              dir={isRTL ? "rtl" : ""}
            >
              <h3 className="text-2xl font-bold mb-2">
                {t("Youth Against Drugs")}
              </h3>
              <p className="text-lg mb-2">{t("Founded in 1981")}</p>
              <p className="text-lg mb-2">
                {t(
                  "Registered with the Ministry of Interior under No. 164/AD, member of the National Council for Combating Drugs in the Cabinet under No. 10, meeting No. 23 dated 1992/4/22."
                )}
              </p>
              <p className="text-lg mb-4 flex-grow">
                {t(
                  "Works to protect youth from the dangers of drugs, smoking, alcohol addiction, and AIDS."
                )}
              </p>
            </div>
          </div>
          <div className="col-span-1 flex flex-col min-h-full">
            <div
              className="bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col flex-grow"
              dir={isRTL ? "rtl" : ""}
            >
              <h3 className="text-2xl font-bold mb-2">
                {t("Youth Charitable Assembly")}
              </h3>
              <p className="text-lg mb-2">{t("Founded in 2000")}</p>
              <p className="text-lg mb-2">
                {t(
                  "Licensed by the Ministry of Interior and Municipalities under No. 82/AD."
                )}
              </p>
              <p className="text-lg mb-4 flex-grow">
                {t(
                  "An association specialized in helping the children and families of addicted individuals, homeless individuals, and orphans."
                )}
              </p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
          <div className="col-span-1 flex flex-col min-h-full">
            <div
              className="bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col flex-grow"
              dir={isRTL ? "rtl" : ""}
            >
              <h3 className="text-2xl font-bold mb-2">{t("JAD Europe")}</h3>
              <p className="text-lg mb-2">{t("Founded in 2001")}</p>
              <p className="text-lg mb-2">
                {t(
                  "The first licensed Arab association operating in the European Union, licensed in Germany, Staufen Court / European Union, under No. 519."
                )}
              </p>
              <p className="text-lg mb-4 flex-grow">
                {t(
                  "Its main activities include protecting the Lebanese community in Europe from the dangers of drugs, providing therapeutic, training, and sports programs against drugs."
                )}
              </p>
            </div>
          </div>
          <div className="col-span-1 flex flex-col min-h-full">
            <div
              className="bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col flex-grow"
              dir={isRTL ? "rtl" : ""}
            >
              <h3 className="text-2xl font-bold mb-2">
                {t("Sports Against Drugs")}
              </h3>
              <p className="text-lg mb-2">{t("Founded in 2002")}</p>
              <p className="text-lg mb-2">
                {t(
                  "Licensed by the Ministry of Youth and Sports under No. 1/66 - 2014."
                )}
              </p>
              <p className="text-lg mb-4 flex-grow">
                {t(
                  "Includes nine different sports teams and is a member of the Lebanese Basketball Federation. Its goal is to integrate addicts and protect youth from the dangers of drugs through sports."
                )}
              </p>
            </div>
          </div>
          <div className="col-span-1 flex flex-col min-h-full">
            <div
              className="bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col flex-grow"
              dir={isRTL ? "rtl" : ""}
            >
              <h3 className="text-2xl font-bold mb-2">
                {t("The Arab Federation of N.G.O. for Drug Abuse Prevention")}
              </h3>
              <p className="text-lg mb-2">
                {t("Headquarters: Egypt, Cairo, since 2006")}
              </p>
              <p className="text-lg mb-4 flex-grow">
                {t("JAD represents Lebanon in the federation.")}
              </p>
            </div>
          </div>
          <div className="col-span-1 flex flex-col min-h-full">
            <div
              className="bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col flex-grow"
              dir={isRTL ? "rtl" : ""}
            >
              <h3 className="text-2xl font-bold mb-2">
                {t("Parents Against Drugs")}
              </h3>
              <p className="text-lg mb-2">{t("Founded in 2001")}</p>
              <p className="text-lg mb-2">
                {t(
                  "Registered with the Ministry of Interior and Municipalities under No. 164/AD."
                )}
              </p>
              <p className="text-lg mb-4 flex-grow">
                {t(
                  "A committee specialized in solving parental issues, which are the primary cause of drug addiction. It includes a specialized team that offers training and awareness programs for parents."
                )}
              </p>
            </div>
          </div>
          <div
            className={`col-span-1 flex flex-col min-h-full ${
              isRTL ? "md:col-start-2" : ""
            }`}
          >
            <div
              className="bg-gray-100 p-6 rounded-lg shadow-lg flex flex-col flex-grow"
              dir={isRTL ? "rtl" : ""}
            >
              <h3 className="text-2xl font-bold mb-2">
                {t("Awareness Program Related to the Risk of Mines")}
              </h3>
              <p className="text-lg mb-2">
                {t(
                  "JAD is a founding member of the office working in the field of awareness and guidance about the dangers of mines since 2006."
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
